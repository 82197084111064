import {RefObject, useEffect, useRef, useState} from 'react';
import {AppState, useAuth0} from '@auth0/auth0-react';
import {Outlet, useLocation, useNavigate, useParams} from 'react-router-dom';
import LogoutButton from './components/buttons/LogoutButton';
import {Bars3Icon, BuildingLibraryIcon} from '@heroicons/react/20/solid';
import {Link} from 'react-router-dom';
import SubscribeModal from './components/modals/Subscribe';
import {SightglassClient} from './api/Client';
import {UserContext, WatcherContext} from './context';
import WelcomeDashboard from './pages/WelcomeDashboard';
import {ModalType} from './components/modals';
import {DialogMountPoint} from './components/buttons/ConfirmButton';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Watcher} from './Watcher';
import {CreditCardIcon, ExclamationCircleIcon} from '@heroicons/react/24/solid';
import {CurrentUser} from '../bindings/api/CurrentUser';
import {AxiosError} from 'axios';
import {TimelineIcon} from './pages/Timeline';

function RobotIcon() {
  return <div>🤖</div>;
}

const LEGAL_TABS = [{label: 'Timeline', path: '/timeline', icon: TimelineIcon}];
const TABS = [
  // {label: 'Timeline', path: '/timeline', icon: TimelineIcon},
  {label: 'Workflows', path: '/workflows', icon: RobotIcon},
  {label: 'Collections', path: '/collections', icon: BuildingLibraryIcon},
  // {label: 'Connections', path: '/connections', icon: CloudIcon},
  // {label: 'Live Assistant', path: '/noted', icon: CheckBadgeIcon},
  // {label: 'API Keys', loc: '/keys', icon: KeyIcon},
];

const DEFAULT_LOGIN_ERROR = 'Unable to login. Please try again.';

function App() {
  const {
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    logout,
    loginWithRedirect,
  } = useAuth0();
  const [userContextData, setUserContextData] = useState<UserContext>({
    setProfile: () => {},
  });
  const [watcherContextData, setWatcherContextData] = useState<WatcherContext>(
    new Watcher()
  );

  const [error, setError] = useState<string | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const subscribeModal = useRef<ModalType>(null);
  const {inviteCode} = useParams();

  const [loginRedirect, setLoginRedirect] = useState<boolean>(false);
  const [selectedTabs, setSelectedTabs] = useState(TABS);

  const logoutWithError = (errorMsg: string | null) => {
    const encodedError = encodeURIComponent(errorMsg ?? DEFAULT_LOGIN_ERROR);
    logout({
      logoutParams: {
        returnTo: `${process.env.REACT_APP_AUTH0_REDIRECT_URI}/?login_error_post_logout=${encodedError}`,
      },
    });
  };

  useEffect(() => {
    if (error && isAuthenticated) {
      setError(null);
    }
  }, [isAuthenticated, error]);

  // Hacky check to make sure timeline users are only on timeline
  useEffect(() => {
    if (userContextData.profile?.features) {
      const {features} = userContextData.profile;
      const isTimeline = features.enabledFeatures.find(
        feat => feat.feature === 'timeline'
      );

      if (
        isTimeline &&
        location.pathname.startsWith('/workflows') &&
        location.pathname.startsWith('/collections')
      ) {
        return navigate('/timeline');
      }
    }
  }, [userContextData, location]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    // Multi pass error handling for social auth login errors.
    // When a social auth fails due to early access rejection (no signup) the auth
    // is successful, but is rejected. In this case we need to logout to make sure
    // the user is fully cleared (to allow login to work), but keep the error message
    if (
      queryParams.has('login_error_post_logout') ||
      queryParams.has('error')
    ) {
      const errorMessage =
        queryParams.get('login_error_post_logout') ||
        queryParams.get('error_description');
      setError(errorMessage);
    }
  }, [location.search, logout, navigate]);

  useEffect(() => {
    if (error) {
      return;
    }

    if (!isLoading && isAuthenticated) {
      const updateContext = async () => {
        const token = await getAccessTokenSilently();
        const client = new SightglassClient(token);

        let userData: CurrentUser;
        try {
          userData = await client.auth.getUserProfile({
            token: null,
            inviteCode: inviteCode ?? null,
          });
        } catch (err: any | AxiosError) {
          if (err.response && err.response.data) {
            logoutWithError(err.response.data?.message ?? DEFAULT_LOGIN_ERROR);
          } else {
            logoutWithError(DEFAULT_LOGIN_ERROR);
          }
          return;
        }

        setUserContextData(ctxt => {
          const updated = {...ctxt};
          updated.authToken = token;
          updated.client = client;
          updated.profile = userData;
          updated.setProfile = data => {
            setUserContextData(ctxt => ({profile: data, ...ctxt}));
          };
          return updated;
        });

        setWatcherContextData(watcher => {
          watcher.setClient(client);
          return watcher;
        });

        // Check user subscription
        if (!userData.subscriptionId && subscribeModal.current) {
          const hasShownPopup = sessionStorage.getItem('subscribePopupShown');
          if (!hasShownPopup) {
            sessionStorage.setItem('subscribePopupShown', 'true');
            subscribeModal.current?.showModal();
          }
        }
      };

      setError(null);
      updateContext().catch(console.error);
    } else if (!isLoading && !isAuthenticated) {
      const appState: AppState = {
        returnTo: `${location.pathname}${location.search}`,
      };
      let authorizationParams;
      if (inviteCode) {
        appState.inviteCode = inviteCode;
        authorizationParams = {
          screen_hint: 'signup',
        };
      }
      setLoginRedirect(true);
      loginWithRedirect({
        appState: appState,
        authorizationParams: authorizationParams,
      });
    }
  }, [
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    inviteCode,
    location.pathname,
    location.search,
    loginWithRedirect,
  ]);

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      if (userContextData.profile?.features) {
        const is_enabled =
          userContextData.profile.features.enabledFeatures.find(
            enabledFeature => {
              return enabledFeature.feature === 'timeline';
            }
          );

        if (is_enabled) {
          setSelectedTabs(LEGAL_TABS);
        }
      } else {
        setSelectedTabs(TABS);
      }
    }
  }, [userContextData]);

  const tabComponents = selectedTabs.map(tab => {
    if (!tab.path) {
      return null;
    }

    const isSelected = window.location.pathname === tab.path;
    return (
      <li key={tab.path}>
        <Link
          to={tab.path}
          className={`flex flex-row items-center justify-start ${
            isSelected ? 'bg-neutral text-neutral-content' : 'bg-inherit'
          }`}
        >
          <tab.icon className="w-4" />
          {tab.label}
        </Link>
      </li>
    );
  });

  return (
    <UserContext.Provider value={userContextData}>
      <WatcherContext.Provider value={watcherContextData}>
        <div className="w-full navbar bg-base-300 relative">
          <div className="navbar-start">
            <div className="dropdown">
              <div
                tabIndex={0}
                role="button"
                className="btn btn-ghost md:hidden"
              >
                <Bars3Icon className="w-6" />
              </div>
              <ul
                tabIndex={0}
                className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52"
              >
                {tabComponents}
              </ul>
            </div>
            <Link to={'/'} className="flex-1 pr-2 lg:px-2 mr-2 lg:mx-2">
              <img
                src={`${process.env.PUBLIC_URL}/logo@2x.png`}
                className="w-10 ml-2 lg:ml-6"
                alt="Sightglass Logo"
              />
            </Link>
          </div>
          <div className="navbar-center hidden md:flex">
            <ul className="menu menu-horizontal gap-2">{tabComponents}</ul>
          </div>
          <div className="navbar-end">
            <ul className="menu menu-horizontal px-1">
              {isAuthenticated && !isLoading ? (
                <Profile plansModal={subscribeModal} />
              ) : null}
            </ul>
          </div>
        </div>
        <div className="flex flex-col items-center w-full mx-auto mb-36">
          {error ? (
            <div className="alert alert-error mb-8">
              <ExclamationCircleIcon className="w-8 h-8" />
              {error}
            </div>
          ) : null}
          {isAuthenticated &&
          !isLoading &&
          userContextData &&
          userContextData.profile ? (
            <Outlet />
          ) : (
            <WelcomeDashboard redirecting={loginRedirect} />
          )}
        </div>
        <SubscribeModal modalRef={subscribeModal} />
        <DialogMountPoint />
        <ToastContainer position="bottom-right" theme="dark" />
      </WatcherContext.Provider>
    </UserContext.Provider>
  );
}

interface ProfileProps {
  plansModal: RefObject<ModalType>;
}

function Profile({plansModal}: ProfileProps) {
  const {user, isAuthenticated, isLoading} = useAuth0();
  if (isLoading) {
    return <li>Loading ...</li>;
  }

  if (isAuthenticated && user) {
    return (
      <li>
        <details>
          <summary>
            <div className="flex flex-row normal-case space-x-2 items-center">
              <img
                src={user.picture}
                alt={user.name}
                className="h-4 w-4 rounded-full"
              />
              <span>{user.name}</span>
            </div>
          </summary>
          <ul className="p-2 bg-base-100 mt-2 z-50 shadow-lg menu dropdown-content rounded-box border border-neutral w-fit">
            <li>
              <a onClick={() => plansModal.current?.showModal()}>
                <CreditCardIcon className="w-4" />
                Billing & Plans
              </a>
            </li>
            <li>
              <LogoutButton />
            </li>
          </ul>
        </details>
      </li>
    );
  } else {
    return null;
  }
}

export default App;
