import {useState, useEffect, useCallback, useContext} from 'react';
import debounce from 'lodash/debounce';
import {
  MagnifyingGlassCircleIcon as SearchIcon,
  XCircleIcon,
} from '@heroicons/react/20/solid';
import {UserContext} from '../../context';
import {
  DocumentSearchResponse,
  SearchUpdate,
} from '../../api/client/collection';

export interface CollectionSearchProps {
  collection: string;
  onChange: (filteredDocs: DocumentSearchResponse[] | undefined) => void;
}

function CollectionSearch({collection, onChange}: CollectionSearchProps) {
  const {client} = useContext(UserContext);
  const [inputValue, setInputValue] = useState('');
  const [response, setResponse] = useState<
    DocumentSearchResponse[] | undefined
  >(undefined);
  const [searching, setSearching] = useState(false);

  const handleChange = useCallback(
    debounce((query: string) => {
      if (query && query.trim().length > 0) {
        setSearching(true);
        const update = (update: SearchUpdate) => {
          if (update.update && update.update.result) {
            setResponse(update.update.result);
          }
        };
        client?.collection
          .search(collection, query, [])
          .then(_response => {
            // if (response.result) {
            //   // TODO this is an issue if it arrives after a full delete for the input text
            //   // leaving the search result in an incorrect state
            // }
          })
          .finally(() => {
            setSearching(false);
          });
      } else {
        setResponse(undefined);
      }
    }, 500),
    [client]
  );

  useEffect(() => {
    if (!response || (inputValue && inputValue.trim().length >= 0)) {
      onChange(response);
    }
  }, [response, inputValue, onChange]);

  useEffect(() => {
    handleChange(inputValue);
  }, [inputValue, handleChange]);

  return (
    <div className="px-4 w-full sm:w-1/2 md:w-3/4 relative">
      <div className="flex flex-row pb-2">
        <input
          type="text"
          placeholder="Search for Documents"
          className="w-full p-2 font-bold placeholder-gray-500 border-neutral-700 rounded-lg hover:border-accent input"
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
        />
        <div className="-ml-10 md:-ml-12 pt-1 md:pt-2">
          {searching ? (
            <span className="h-8 loading loading-ring loading-md"></span>
          ) : response ? (
            <XCircleIcon
              className="h-8 w-8"
              onClick={() => {
                setResponse(undefined);
                setInputValue('');
              }}
            ></XCircleIcon>
          ) : (
            <SearchIcon className="h-8 w-8 text-accent" />
          )}
        </div>
      </div>
    </div>
  );
}

export default CollectionSearch;
