import {ReactNode, useEffect, useRef, useState} from 'react';
import {
  BuildingOfficeIcon,
  DocumentChartBarIcon,
  DocumentIcon,
  EnvelopeIcon,
  FolderIcon,
  PresentationChartLineIcon,
  TrashIcon,
  UserGroupIcon,
} from '@heroicons/react/24/solid';
import {DocumentResponse} from '../../../bindings/api/DocumentResponse';
import {
  DocumentCitations,
  DocumentSearchResponse,
} from '../../api/client/collection';
import {DateTime} from 'luxon';
import {RelevantIssue} from './TimelineTagBar';
import {SavedChecklist} from '../../api/client/checklist';
import {PlusIcon} from '@heroicons/react/20/solid';

export function TLDocumentIcon({documentType}: {documentType?: string}) {
  let icon = <DocumentIcon className="w-4" />;

  if (documentType === 'Bank Statement') {
    icon = <DocumentChartBarIcon className="w-4" />;
  } else if (documentType === 'Email') {
    icon = <EnvelopeIcon className="w-4" />;
  } else if (documentType === 'Financial Document') {
    icon = <BuildingOfficeIcon className="w-4" />;
  } else if (documentType === 'SEC Filing') {
    icon = <PresentationChartLineIcon className="w-4" />;
  }

  return <TLItemIcon icon={icon}></TLItemIcon>;
}

function TLItemIcon({icon}: {icon: React.ReactNode}) {
  return (
    <div className="flex items-center justify-center w-8 h-8 rounded-full bg-gray-200 text-black shadow md:order-1">
      {icon}
    </div>
  );
}

interface TimelineProps {
  searchQuery: string | null;
  onAddTag: (documentUuid: string, newTag: string) => void;
  onDeleteTag: (documentUuid: string, tag: string) => void;
  onCitationSelect: (citation: string) => void;
  onDocumentSelect: (uuid: DocumentResponse) => void;
  documents: DocumentResponse[];
  searchResults: DocumentSearchResponse[] | null;
  checklist?: SavedChecklist;
}

export function Timeline({
  searchQuery,
  documents,
  searchResults,
  onAddTag,
  onDeleteTag,
  onDocumentSelect,
  onCitationSelect,
  checklist,
}: TimelineProps) {
  const [selectedIdx, setSelectedIdx] = useState<string>('');

  const searchDocMap: {[key: string]: DocumentSearchResponse} = {};
  if (searchResults) {
    for (const searchResult of searchResults) {
      searchDocMap[searchResult.uuid] = searchResult;
    }
  }

  const filteredDocuments = documents.filter(doc => {
    const result = searchDocMap[doc.uuid];
    if (searchResults && result && result.citationResponse) {
      return result.citationResponse.hasAnswer;
    } else {
      return !searchResults || (searchResults && searchDocMap[doc.uuid]);
    }
  });

  const styles = [
    'relative',
    'space-y-4',
    // Under MD
    'before:absolute',
    'before:inset-0',
    'before:ml-[16px]',
    'before:-translate-x-px',
    'before:h-full',
    'before:w-[2px]',
    'before:bg-slate-600',
    // MD breakpoint
    'md:before:ml-[192px]',
    'md:before:translate-x-0',
  ];

  return (
    <div className="flex flex-col px-4 w-full">
      <div className={styles.join(' ')}>
        {filteredDocuments.map(doc => (
          <TimelineItem
            key={doc.uuid}
            data={doc}
            searchResult={searchDocMap[doc.uuid]}
            searchQuery={searchQuery}
            onClick={() => {
              setSelectedIdx(doc.uuid === selectedIdx ? '' : doc.uuid);
              onDocumentSelect(doc);
            }}
            onAddTag={tag => onAddTag(doc.uuid, tag)}
            onDeleteTag={tag => onDeleteTag(doc.uuid, tag)}
            onCitationSelect={citation => onCitationSelect(citation)}
            isSelected={doc.uuid === selectedIdx}
            checklist={checklist}
          />
        ))}
      </div>
    </div>
  );
}

interface TimelineItemProps {
  data: DocumentResponse;
  checklist?: SavedChecklist;
  onClick?: () => void;
  onCitationSelect?: (citation: string) => void;
  onAddTag?: (newTag: string) => void;
  onDeleteTag?: (tag: string) => void;
  isSelected?: boolean;
  searchResult: DocumentSearchResponse | null;
  searchQuery: string | null;
}

function TimelineItem({
  data,
  isSelected = false,
  onCitationSelect = () => {},
  onClick = () => {},
  onAddTag = () => {},
  onDeleteTag = () => {},
  searchResult,
  searchQuery,
  checklist,
}: TimelineItemProps) {
  const date = data.publishedAt ?? data.createdAt;
  const meta = (data.metadata as any) ?? null;

  const [tags, setTags] = useState<{[key: string]: any[]}>({});

  useEffect(() => {
    const tagMap: {[key: string]: any[]} = {};
    data.tags.forEach(tag => {
      const key = tag.key;
      const value = JSON.parse(tag.value);
      if (tagMap[key]) {
        tagMap[key].push(value);
      } else {
        tagMap[key] = [value];
      }
    });
    setTags(tagMap);
  }, [data.tags]);

  const issueTitleMap: {[_: string]: string} = {};
  checklist?.checklist.items.forEach(item => {
    issueTitleMap[item.uuid] = item.title;
  });

  return (
    <div
      className={`relative p-3 pl-0 rounded ${isSelected ? 'bg-gray-700' : ''}`}
    >
      <div
        onClick={() => onClick()}
        className="md:flex items-center md:space-x-4  hover:cursor-pointer hover:bg-gray-700"
      >
        <div className="flex items-center space-x-4 md:space-x-2 md:space-x-reverse py-4">
          <TLDocumentIcon
            key={`${data.uuid}-icon`}
            documentType={meta?.docType}
          />
          <time className="font-bold w-[170px] text-left md:text-right md:pr-4">
            <FormattedDate isoDateString={date} />
          </time>
        </div>
        <div className="ml-14 flex lg:flex-row lg:items-center flex-col items-start lg:space-x-4 space-y-2 lg:space-y-0">
          <div className="font-bold text-lg text-white">{data.displayName}</div>
        </div>
      </div>
      <div className="w-44"></div>
      <div className="ml-14 md:ml-44 max-w-3xl flex flex-col">
        {tags && (
          <div className="card bg-white rounded text-black">
            <div className="card-body p-4">
              <DocumentTagList
                sectionName={'Labels'}
                icon={<FolderIcon className="w-4" />}
                tags={tags['custom_user'] ?? []}
                isEditable
                onAddTag={tag => onAddTag(tag)}
                onDeleteTag={tag => onDeleteTag(tag)}
              />

              <DocumentTagList
                sectionName={'People'}
                icon={<UserGroupIcon className="w-4" />}
                tags={tags['people'] ?? []}
              />

              {meta?.summary ? (
                <div className="collapse collapse-arrow border-neutral border">
                  <input type="checkbox" defaultChecked />
                  <div className="collapse-title font-medium">Summary</div>
                  <div className="collapse-content">{meta.summary}</div>
                </div>
              ) : null}

              {tags && tags['relevant_issues']?.length > 0 && (
                <div className="collapse collapse-arrow border border-black bg-slate-100">
                  <input type="checkbox" />
                  <div className="collapse-title font-medium">
                    Roadmap Relevance
                  </div>
                  <div className="collapse-content">
                    <div className="flex flex-col gap-4">
                      {tags['relevant_issues']?.map((issue: RelevantIssue) => (
                        <div className="flex flex-col gap-2">
                          <div>
                            <div className="font-bold">
                              {issueTitleMap[issue.issue_uuid]}
                            </div>
                            <p>{issue.explanation}</p>
                          </div>
                          <div className="bg-yellow-100 p-4 italic">
                            {issue.quote}
                          </div>
                        </div>
                      )) ?? null}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {searchResult && (
          <div className="card bg-white mt-3 rounded text-black w-full">
            <div className="card-body items-center p-4">
              {searchResult && searchResult.citationResponse ? (
                <Citation
                  key={`citation-el-${searchResult.uuid}`}
                  query={searchQuery}
                  citation={searchResult.citationResponse}
                  docUUID={searchResult.uuid}
                  onCitationSelect={onCitationSelect}
                />
              ) : (
                <div>Analyzing document...</div>
              )}
              {/* {searchResult &&
                searchResult.context &&
                searchResult.context.map((context, index) => {
                  return (
                    <pre
                      key={`citation-${index}`}
                      className="italic bg-yellow-100 text-slate-500 p-8 flex flex-row gap-4 items-center overflow-x-auto prose"
                    >
                      {context.text}
                    </pre>
                  );
                })} */}
              <div className="text-gray-100">score: {searchResult.score}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

interface CitationProps {
  query: string | null;
  docUUID: string;
  citation: DocumentCitations;
  onCitationSelect: (citation: string) => void;
}

function Citation({query, citation, docUUID, onCitationSelect}: CitationProps) {
  return (
    <>
      <div className="flex flex-col gap-2">
        <div className="font-bold">Relation to "{query ?? 'no query?'}"</div>
        <div>{citation.answer}</div>
      </div>
      {citation.citations.map((context, index) => {
        return (
          <blockquote
            key={`citation-${docUUID}-${index}`}
            className="italic bg-yellow-100 text-slate-500 p-4 flex flex-row gap-4 items-center"
          >
            <button
              className="btn btn-square btn-xs bg-white border-slate-200 text-slate-500 hover:bg-accent hover:text-black"
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();
                onCitationSelect(context.quote);
              }}
            >
              {index + 1}
            </button>
            {context.quote}
          </blockquote>
        );
      })}
    </>
  );
}

function FormattedDate({isoDateString}: {isoDateString: string}) {
  const processedDate = DateTime.fromISO(isoDateString);
  let formattedDate;

  const utc = processedDate.toUTC();
  if (utc.hour === 0 && utc.second === 0 && utc.millisecond === 0) {
    formattedDate = utc.toFormat('LLLL d, yyyy');
  } else {
    formattedDate = processedDate.toLocaleString(DateTime.DATETIME_FULL);
  }
  return <>{formattedDate}</>;
}

interface DocumentTagListProps {
  sectionName?: string;
  tags: string[];
  icon: ReactNode;
  isEditable?: boolean;
  onAddTag?: (newTag: string) => void;
  onDeleteTag?: (tag: string) => void;
}

function DocumentTagList({
  sectionName,
  icon,
  tags,
  isEditable = false,
  onAddTag = () => {},
  onDeleteTag = () => {},
}: DocumentTagListProps) {
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const tagInputRef = useRef<HTMLInputElement>(null);
  const [deleted, setDeleted] = useState<string[]>([]);

  const handleDeleteTag = (tag: string) => {
    setDeleted(deleted => [...deleted, tag]);
    onDeleteTag(tag);
  };

  const saveTag = () => {
    setIsAdding(false);
    if (tagInputRef.current) {
      onAddTag(tagInputRef.current.value ?? '');
      tagInputRef.current.value = '';
    }
  };

  return (
    <div className="flex flex-row gap-4 items-center flex-wrap">
      <div className="flex flex-row gap-2 text-sm font-bold">
        {icon} {sectionName}
      </div>
      {tags.length === 0 && !isEditable ? (
        <div className="italic text-neutral-400">No labels</div>
      ) : null}
      {tags.sort().map((tag: string) => {
        if (deleted.includes(tag)) {
          return null;
        }

        return (
          <div key={tag} className="badge badge-outline items-center">
            <span>{tag}</span>
            {isEditable ? (
              <button
                className="btn btn-xs btn-link"
                onClick={() => handleDeleteTag(tag)}
              >
                <TrashIcon className="w-3 text-error" />
              </button>
            ) : null}
          </div>
        );
      })}
      {isEditable && !isAdding ? (
        <button
          className="btn btn-xs btn-accent"
          onClick={() => setIsAdding(true)}
        >
          <PlusIcon className="w-4" />
          Add label
        </button>
      ) : null}
      {isAdding ? (
        <div className="join">
          <input
            ref={tagInputRef}
            className="input input-xs input-bordered join-item bg-white border-2 border-neutral-300"
          />
          <button
            className="btn btn-xs btn-accent join-item"
            onClick={() => saveTag()}
          >
            Save
          </button>
          <button
            className="btn btn-xs btn-error join-item"
            onClick={() => setIsAdding(false)}
          >
            Cancel
          </button>
        </div>
      ) : null}
    </div>
  );
}
