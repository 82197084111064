import React, {useContext, useEffect, useState} from 'react';
import {SavedChecklist} from '../../api/client/checklist';
import {UserContext} from '../../context';
import {DateTime} from 'luxon';
import {DocumentHistogram} from '../../api/client/collection';
import {Popover} from 'react-tiny-popover';
import {ClockIcon} from '@heroicons/react/24/solid';

export type ToggledTagMap = {[key: string]: boolean};

export interface RelevantIssue {
  issue_uuid: string;
  is_relevant: boolean;
  explanation: string;
  quote: string;
}

export interface DateRange {
  start: DateTime | null;
  end: DateTime | null;
}

interface TagSection {
  key: string;
  label: string;
}

export const TAG_SECTIONS: TagSection[] = [
  {key: 'custom_user', label: 'User Labels'},
  {key: 'people', label: 'People'},
  {key: 'companies', label: 'Companies'},
];

export interface Props {
  collectionUuid: string;
  checklist: SavedChecklist | undefined;

  checkedTags: ToggledTagMap;
  checkedIssues: ToggledTagMap;

  histogram: DocumentHistogram[];

  // Updates to parent state.
  setCheckedIssues: React.Dispatch<React.SetStateAction<ToggledTagMap>>;
  setCheckedTags: React.Dispatch<React.SetStateAction<ToggledTagMap>>;
  setRangeFilter: React.Dispatch<React.SetStateAction<DateRange>>;
}

export function TimelineTagBar({
  checklist,
  checkedIssues,
  checkedTags,
  collectionUuid,
  histogram,
  setCheckedTags,
  setCheckedIssues,
  setRangeFilter,
}: Props) {
  const {client} = useContext(UserContext);

  const [tags, setTags] = useState<{[key: string]: string[]}>({});
  const [relevantIssues, setRelevantIssues] = useState<{
    [documentUuid: string]: RelevantIssue[];
  }>({});

  const [docDateRange, setDocDateRange] = useState<DateRange>({
    start: null,
    end: null,
  });

  const handleIssueFilter = (uuid: string) => {
    setCheckedIssues(issues => {
      const updated = {...issues};
      updated[uuid] = !updated[uuid];
      return updated;
    });
  };

  const handleTagFilter = (section: string, tag: string) => {
    const key = `${section}:${tag}`;
    const updated = {...checkedTags};
    updated[key] = !updated[key];
    setCheckedTags(updated);
  };

  useEffect(() => {
    if (histogram && histogram.length > 0) {
      const start = DateTime.fromISO(histogram[0].date);
      const end = DateTime.fromISO(histogram[histogram.length - 1].date);
      setDocDateRange({start, end});
    }
  }, [histogram]);

  useEffect(() => {
    if (!client) {
      return;
    }

    const runAsync = async () => {
      const tagsResponse = await client?.collection.tags(collectionUuid);
      if (tagsResponse && tagsResponse.result) {
        const userTags = tagsResponse.result.userTags;
        const tagMap: {[key: string]: string[]} = {};
        const issueMap: {[_: string]: RelevantIssue[]} = {};

        for (let i = 0; i < userTags.length; i++) {
          const userTag = userTags[i];
          const tag = tagMap[userTag.key];
          // skip over relevant issues tags
          if (userTag.key === 'relevant_issues' && userTag.value) {
            const issue = JSON.parse(userTag.value) as RelevantIssue;
            issueMap[issue.issue_uuid]
              ? issueMap[issue.issue_uuid].push(issue)
              : (issueMap[issue.issue_uuid] = [issue]);
          } else if (
            TAG_SECTIONS.find(section => section.key === userTag.key)
          ) {
            const value = JSON.parse(userTag.value);
            tag ? tag.push(value) : (tagMap[userTag.key] = [value]);
          }
        }

        const fileTypes = tagsResponse.result.fileTypes;
        const fileTypeCount = fileTypes.length;
        for (let i = 0; i < fileTypeCount; i++) {
          if (i === 0) {
            tagMap['File Types'] = [fileTypes[i].fileType];
          } else {
            tagMap['File Types'].push(fileTypes[i].fileType);
          }
        }

        setRelevantIssues(issueMap);
        setTags(tagMap);
      }
    };

    runAsync().catch(console.error);
  }, [client, collectionUuid]);

  return (
    <div className="flex flex-col gap-6">
      <RangeFilter
        defaultRange={docDateRange}
        onSetDateRange={newDateRange => setRangeFilter(newDateRange)}
      />

      <TagListSection
        sectionName={'Roadmap'}
        tags={
          checklist?.checklist.items.map(item => ({
            label: item.title,
            id: item.uuid,
            isChecked: checkedIssues[item.uuid] ?? false,
            count: relevantIssues[item.uuid]?.length ?? 0,
          })) ?? []
        }
        onTagToggle={tag => handleIssueFilter(tag)}
        showCount
      />

      {TAG_SECTIONS.map(section => (
        <TagListSection
          key={section.key}
          sectionName={section.label}
          tags={(tags[section.key] ?? []).map(tag => ({
            label: tag,
            isChecked: checkedTags[`${section.key}:${tag}`] ?? false,
            count: 1,
          }))}
          onTagToggle={tag => handleTagFilter(section.key, tag)}
        />
      ))}
    </div>
  );
}

interface RangeFilterProps {
  defaultRange: DateRange;
  onSetDateRange: (_: DateRange) => void;
}

export function RangeFilter({defaultRange, onSetDateRange}: RangeFilterProps) {
  const [dateRange, setDateRange] = useState<DateRange>({
    start: defaultRange.start ?? DateTime.now(),
    end: defaultRange.end ?? DateTime.now(),
  });
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  return (
    <div className="flex flex-row gap-2 items-center flex-wrap">
      <Popover
        isOpen={isPopoverOpen}
        positions={['top', 'bottom', 'right', 'left']} // preferred positions by priority
        content={
          <div className="flex flex-col gap-2 card card-compact bg-base-100 shadow-xl border border-neutral-content">
            <div className="flex flex-row gap-2 card-body items-center">
              <input
                className="input-md"
                type="date"
                id="start"
                name="range-start"
                defaultValue={dateRange.start?.toISODate() ?? undefined}
                value={dateRange.start?.toISODate() ?? undefined}
                onChange={event => {
                  if (event.currentTarget) {
                    setDateRange(curRange => ({
                      start: DateTime.fromISO(event.currentTarget.value),
                      end: curRange.end,
                    }));
                  }
                }}
              />
              <span className="font-bold text-xl text-center">-</span>
              <input
                className="input-md"
                type="date"
                id="end"
                name="range-end"
                defaultValue={dateRange.end?.toISODate() ?? undefined}
                value={dateRange.end?.toISODate() ?? undefined}
                onChange={event => {
                  if (event.currentTarget) {
                    setDateRange(curRange => ({
                      start: curRange.start,
                      end: DateTime.fromISO(event.currentTarget.value),
                    }));
                  }
                }}
              />
            </div>
            <div className="flex flex-row gap-2 card-actions justify-between pb-2 px-2">
              <button
                className="btn btn-ghost btn-sm"
                onClick={() =>
                  setDateRange({
                    start: defaultRange.start ?? DateTime.now(),
                    end: defaultRange.end ?? DateTime.now(),
                  })
                }
              >
                Reset Time Range
              </button>

              <div className="flex flex-row gap-2">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => onSetDateRange(dateRange)}
                >
                  Ok
                </button>
                <button
                  className="btn btn-secondary btn-sm"
                  onClick={() => setIsPopoverOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        }
      >
        <div className="flex flex-row gap-1 items-center mt-1">
          <button
            className="btn btn-xs btn-primary btn-square mr-2"
            onClick={() => {
              setIsPopoverOpen(current => {
                return !current;
              });
            }}
          >
            <ClockIcon className="w-4" />
          </button>
          <div className="flex flex-row flex-wrap gap-1">
            <span className="text-accent">
              {dateRange.start?.toLocaleString()}
            </span>
            <span>to</span>
            <span className="text-accent">
              {dateRange.end?.toLocaleString()}
            </span>
          </div>
        </div>
      </Popover>
    </div>
  );
}

interface Tag {
  label: string;
  id?: string;
  isChecked: boolean;
  count: number;
}

export function TagListSection({
  sectionName,
  tags,
  onTagToggle = () => {},
  readOnly = false,
  showCount = false,
}: {
  sectionName: string;
  tags: Tag[];
  onTagToggle?: (label: string) => void;
  readOnly?: boolean;
  showCount?: boolean;
}) {
  tags.sort((a, b) =>
    a.label.toLowerCase().localeCompare(b.label.toLowerCase())
  );
  return (
    <div className="flex flex-col gap-2">
      <div className="font-bold text-lg text-accent capitalize">
        {sectionName}
      </div>
      <div className="overflow-x-auto min-h-[48px] max-h-[256px] border rounded border-neutral-600 p-2">
        <ul className="list-none">
          {tags.map(tag => (
            <TLFilter
              key={`filter-${sectionName}-${tag.label}`}
              isChecked={tag.isChecked}
              label={tag.label}
              count={tag.count}
              onClick={() => onTagToggle(tag.id ?? tag.label)}
              readOnly={readOnly}
              showCount={showCount}
            />
          ))}
        </ul>
      </div>
    </div>
  );
}

function TLFilter({
  label,
  count,
  isChecked,
  onClick = () => {},
  readOnly = false,
  showCount = false,
}: {
  label: string;
  isChecked: boolean;
  onClick: () => void;
  readOnly: boolean;
  showCount: boolean;
  count: number;
}) {
  return (
    <li className="w-fit">
      <div className="form-control">
        <label className="label cursor-pointer items-center">
          {readOnly ? null : (
            <input
              type="checkbox"
              className="checkbox checkbox-xs mr-2"
              checked={isChecked}
              readOnly={readOnly}
              onChange={onClick}
            />
          )}
          <span className="label-text text-sm">
            {label} {showCount ? `(${count})` : null}
          </span>
        </label>
      </div>
    </li>
  );
}
