import axios, {AxiosError, AxiosProgressEvent} from 'axios';
import {API_ENDPOINT, GeneralTaskResponse, SightglassClient} from '../Client';
import {ApiResponse} from '../../../bindings/api/ApiResponse';
import {CollectionListing} from '../../../bindings/api/CollectionListing';
import {CollectionResponse} from '../../../bindings/api/CollectionResponse';
import {DetailedDocumentResponse} from '../../../bindings/api/DetailedDocumentResponse';
import {DocumentResponse} from '../../../bindings/api/DocumentResponse';
import {DocumentSearchFilter} from '../../../bindings/api/DocumentSearchFilter';
import {DocumentSelectionFilter} from '../../../bindings/api/DocumentSelectionFilter';
import {fetchEventSource} from '@microsoft/fetch-event-source';
import {LensType} from '../../../bindings/api/LensType';
import {UserTag} from '../../../bindings/api/UserTag';

export interface CollectionUpdateRequest {
  name: string;
}

export interface DocumentSearchResponse {
  uuid: string;
  displayName: string;
  score: number;
  context?: SearchContext[];
  metadata?: any;
  citationResponse?: DocumentCitations;
}

export interface SearchContext {
  text: string;
  score: number;
  index: number;
}

export interface DocumentCitations {
  documentUuid: string;
  hasAnswer: boolean;
  answer: string;
  citations: Citations[];
}

export interface Citations {
  quote: string;
}

export interface CollectionTags {
  fileTypes: DocumentFileType[];
  userTags: UserTag[];
}

export interface DocumentFileType {
  fileType: string;
  count: number;
}

export interface DocumentHistogram {
  date: string;
  count: number;
}

export enum SearchUpdateType {
  SearchResult = 'SearchResult',
  SearchStart = 'SearchStart',
  CitationResult = 'CitationResult',
  SearchEnd = 'SearchEnd',
}

export interface SearchUpdate {
  updateType: SearchUpdateType;
  update?: ApiResponse<DocumentSearchResponse[]>;
}

export interface PresignedUrlResponse {
  uuid: string;
  uploadUrl: string;
  name: string;
}

export class CollectionEndpoints {
  client: SightglassClient;
  module: string;

  constructor(client: SightglassClient) {
    this.client = client;
    this.module = 'collections';
  }

  async create(
    name: string,
    type: LensType
  ): Promise<ApiResponse<CollectionResponse>> {
    const data = {
      name,
      configuration: {
        typeConfig: type,
      },
    };

    return this.client.postJson<CollectionResponse>(this.module, data);
  }

  async list(filterBy?: LensType): Promise<ApiResponse<CollectionListing[]>> {
    return this.client.getJson(this.module, {filterBy});
  }

  async get(uuid: string): Promise<ApiResponse<CollectionResponse>> {
    return this.client.getJson(`${this.module}/${uuid}`);
  }

  async delete(uuid: string): Promise<ApiResponse<boolean>> {
    return this.client.deleteJson<ApiResponse<boolean>>(`collections/${uuid}`);
  }

  async update(
    uuid: string,
    updates: CollectionUpdateRequest
  ): Promise<ApiResponse<CollectionResponse>> {
    return this.client.patchJson(`${this.module}/${uuid}`, updates);
  }

  async listDocuments(
    collection: string,
    filter: DocumentSelectionFilter | undefined = {
      filter: null,
      orderBy: null,
      tagFilter: null,
    }
  ): Promise<ApiResponse<DocumentResponse[]>> {
    return this.client.postJson<DocumentResponse[]>(
      `${this.module}/${collection}/documents`,
      filter
    );
  }

  async histogram(
    collection: string
  ): Promise<ApiResponse<DocumentHistogram[]>> {
    return this.client.getJson<ApiResponse<DocumentHistogram[]>>(
      `${this.module}/${collection}/histogram`
    );
  }

  async tags(collection: string): Promise<ApiResponse<CollectionTags>> {
    return this.client.getJson<ApiResponse<CollectionTags>>(
      `${this.module}/${collection}/tags`
    );
  }

  async listDocumentsByPublished(
    collection: string
  ): Promise<ApiResponse<DocumentResponse[]>> {
    return this.listDocuments(collection, {
      orderBy: {ASC: 'publishedAt'},
      tagFilter: null,
      filter: null,
    });
  }

  async downloadDocumentBlob(
    collection: string,
    document: string,
    returnProcessed?: boolean
  ): Promise<Blob> {
    return this.client.get(
      `${this.module}/${collection}/documents/${document}/download`,
      returnProcessed !== undefined ? {returnProcessed} : {},
      {responseType: 'blob'}
    );
  }

  async deleteDocument(
    collection: string,
    document: string
  ): Promise<ApiResponse<any>> {
    return this.client.deleteJson<ApiResponse<any>>(
      `${this.module}/${collection}/documents/${document}`
    );
  }

  async getDocumentDetail(
    collection: string,
    document: string
  ): Promise<ApiResponse<DetailedDocumentResponse>> {
    return this.client.getJson<ApiResponse<DetailedDocumentResponse>>(
      `${this.module}/${collection}/documents/${document}`,
      {detailed: 'true'}
    );
  }

  async updateDocument(
    collection: string,
    document: string,
    updates: {[key: string]: any}
  ): Promise<ApiResponse<DocumentResponse>> {
    return this.client.patchJson<DocumentResponse>(
      `${this.module}/${collection}/documents/${document}`,
      {
        text: null,
        displayName: updates.displayName,
        metadata: updates.metadata,
        tags: updates.tags,
        removeTags: updates.removeTags,
      }
    );
  }

  async extractFromDocument(
    collection: string,
    document: string,
    query: string,
    schema: any
  ): Promise<ApiResponse<string>> {
    return this.client.postJson(
      `${this.module}/${collection}/documents/${document}/tasks/extract`,
      {
        query: query,
        useContext: true,
        jsonSchema: schema,
      }
    );
  }

  async getAllTasksForCollection(
    collection: string
  ): Promise<ApiResponse<GeneralTaskResponse[]>> {
    return this.client.getJson<ApiResponse<GeneralTaskResponse[]>>(
      `${this.module}/${collection}/task`
    );
  }

  async presignUpload(
    collection: string,
    documents: string[]
  ): Promise<ApiResponse<PresignedUrlResponse[]>> {
    return this.client.postJson(`${this.module}/${collection}/preupload`, {
      names: documents,
    });
  }

  async getDownloadUrl(
    collection: string,
    documents: string
  ): Promise<ApiResponse<string>> {
    return this.client.getJson(
      `${this.module}/${collection}/documents/${documents}/downloadUrl`
    );
  }

  async finishUpload(
    collection: string,
    document: string
  ): Promise<ApiResponse<DocumentResponse>> {
    return this.client.postJson(
      `${API_ENDPOINT}/${this.module}/${collection}/documents/${document}/uploaded`,
      {}
    );
  }

  async uploadPresignedDocument(
    url: string,
    fileName: string,
    file: Blob,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
  ): Promise<any> {
    const data: {[key: string]: any} = {[fileName]: file};

    return axios
      .putForm(url, data, {
        onUploadProgress: progressEvent => {
          if (onUploadProgress) {
            onUploadProgress(progressEvent);
          }
        },
      })
      .then(resp => resp.data)
      .catch((err: AxiosError) => {
        console.error('axios error: ', err);
        throw err;
      })
      .catch(err => {
        console.error('other error: ', err);
        throw err;
      });
  }

  async uploadDocument(
    collection_uuid: string,
    fileName: string,
    fileSize: number,
    file: Blob,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
  ): Promise<ApiResponse<DocumentResponse[]>> {
    const file_name = `${fileName}_${fileSize}`;
    const data: {[key: string]: any} = {[file_name]: file};

    return this.client.client
      .postForm(
        `${API_ENDPOINT}/${this.module}/${collection_uuid}/upload`,
        data,
        {
          headers: {Authorization: `Bearer ${this.client.token}`},
          onUploadProgress: progressEvent => {
            if (onUploadProgress) {
              onUploadProgress(progressEvent);
            }
          },
        }
      )
      .then(resp => resp.data)
      .catch((err: AxiosError) => {
        console.error('axios error: ', err);
        throw err;
      })
      .catch(err => {
        console.error('other error: ', err);
        throw err;
      });
  }

  async search(
    collection_uuid: string,
    query: string,
    tags: Array<[string, string]> = [],
    {
      limit = 100,
      searchStreamCallback = () => {},
      withCitations = true,
    }: SearchOptions = {}
  ) {
    const endpoint = `${API_ENDPOINT}/${this.module}/${collection_uuid}/search`;
    const params: DocumentSearchFilter = {
      limit,
      query,
      tags,
      withCitations: withCitations,
      withContext: true,
      withMetadata: true,
      start: null,
    };

    return fetchEventSource(endpoint, {
      method: 'POST',
      headers: {
        Accept: 'text/event-stream',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.client.token}`,
      },
      body: JSON.stringify(params),
      onmessage: event => {
        // Empty data message is used for keepalive
        if (event.data.length !== 0) {
          const eventResponse = JSON.parse(event.data);
          if (typeof eventResponse === 'string') {
            if (eventResponse === 'SearchStart') {
              searchStreamCallback({
                updateType: SearchUpdateType.SearchStart,
              });
            } else if (eventResponse === 'SearchEnd') {
              searchStreamCallback({
                updateType: SearchUpdateType.SearchEnd,
              });
            }
          } else {
            const result: ApiResponse<DocumentSearchResponse[]> =
              eventResponse['SearchResult'];

            searchStreamCallback({
              updateType: SearchUpdateType.SearchResult,
              update: result,
            });
          }
        }
      },
      onclose: () => {
        searchStreamCallback({
          updateType: SearchUpdateType.SearchEnd,
        });
      },
    });
  }

  async searchDocumentContext(
    collection_uuid: string,
    document_uuid: string,
    query: string
  ): Promise<ApiResponse<DocumentSearchResponse[]>> {
    return this.client.client
      .post(
        `${API_ENDPOINT}/${this.module}/${collection_uuid}/documents/${document_uuid}/search`,
        {
          query,
          withContext: true,
          withMetadata: true,
        }
      )
      .then(resp => resp.data)
      .catch((err: AxiosError) => {
        console.error('axios error: ', err);
        throw err;
      })
      .catch(err => {
        console.error('other error: ', err);
        throw err;
      });
  }
}

export interface SearchOptions {
  limit?: number;
  searchStreamCallback?: (update: SearchUpdate) => void;
  withCitations?: boolean;
}
